<template>
  <div class="d-flex align-items-center min-vh-100 login-container">
    <b-container>
      <b-row class="justify-content-center">
        <b-col md="9" lg="6" class="login">
          <b-card-group>
            <b-card class="p-4 shadow-lg">
              <b-card-body>
                <b-form>
                  <div class="text-center">
                    <img src="/logo.png" alt />
                    <hr class="my-4" />
                  </div>
                  <!-- <div>
                    <InputText
                      class="my-3 login-input"
                      v-model="form.username"
                      textFloat="Email"
                      placeholder="Email"
                      type="email"
                      name="email"
                      isRequired
                      :isValidate="$v.form.username.$error"
                      :v="$v.form.username"
                      @onKeyup="submitFormOnInput"
                    />
                    <InputText
                      class="my-3 login-input"
                      v-model="form.password"
                      textFloat="Password"
                      placeholder="Password"
                      type="password"
                      name="password"
                      isRequired
                      :isValidate="$v.form.password.$error"
                      :v="$v.form.password"
                      @onKeyup="submitFormOnInput"
                    />
                    <b-row v-if="error != ''" class="m-2">
                      <span class="text-danger">
                        {{error}}
                        <br />
                        {{" "}}
                      </span>
                    </b-row>
                    <b-row>
                      <c-col>
                        <b-button
                          variant="primary"
                          type="button"
                          class="px-4 login-btn"
                          @click="checkForm"
                          :disabled="isDisable"
                        >Login</b-button>
                      </c-col>
                    </b-row>
                  </div> -->
                  <div>
                    <!-- <b-row class="my-2">
                      <c-col class="text-center">
                        <b>OR</b>
                      </c-col>
                    </b-row> -->
                    <b-row>
                      <c-col>
                        <ButtonLoginAzureAD
                          :clientId="$settingAzure.client_id"
                          :endpoint="$settingAzure.endpoint"
                        />
                      </c-col>
                    </b-row>
                  </div>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import InputText from "@/components/inputs/InputText";
import ButtonLoginAzureAD from "@/components/inputs/ButtonLoginAzureAD";
//import SidebarItems from "@/containers/SidebarItems";

export default {
  name: "Login",
  //extends: SidebarItems,
  components: {
    InputText,
    ButtonLoginAzureAD,
  },
  data() {
    return {
      error: "",
      form: {
        username: "",
        password: "",
      },
      isLoading: false,
      isDisable: false,
      settingAzure: null,
    };
  },
  validations() {
    return {
      form: {
        username: { required, email },
        password: { required },
      },
    };
  },
  created() {
    this.settingAzure = this.$settingAzure;

   
  },
  methods: {
    checkForm: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isLoading = true;
      this.isDisable = true;
      this.error = "";
      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/authenticate`,
        null,
        this.$headers,
        this.form
      );
      this.isLoading = false;
      if (data.result == 1) {
        await this.$cookies.set("token", data.detail, 60 * 60 * 24 * 30);
        window.location.href = "/";
      } else {
        this.error = data.message;
        this.isDisable = false;
      }
    },
    submitFormOnInput: function (e) {
      if (e.keyCode === 13) {
        this.checkForm();
      }
    },
  },
};
</script>
