<template>
  <div>
    <b-container class="container-box">
      <CRow class="w-100 no-gutters">
        <CCol class="text-center text-lg-left">
          <h1 class="mr-sm-4 mb-4">REPORT RICH MENUS</h1>
        </CCol>
        <CCol lg="6" class="text-center text-lg-right">
          <b-button v-b-toggle.sidebar-1 class="btn-filter mr-0 mr-sm-1">
            <font-awesome-icon
              icon="filter"
              title="filter-btn"
              class="text-white mr-0 mr-sm-1"
            />
            <span class="d-none d-sm-inline">FILTER</span>
          </b-button>
          <b-dropdown
            id="dropdown-1"
            class="mr-2 btn-mains small-dropdown medium-dd"
            right
            no-flip
          >
            <template v-slot:button-content>
              <font-awesome-icon
                icon="ellipsis-v"
                title="filter-btn"
                class="text-white d-sm-none"
              />
              <span class="d-none d-sm-inline">ACTION</span>
            </template>
            <b-dropdown-item @click="exportData(1, 1)"
              >Export Summary (Excel)</b-dropdown-item
            >
            <b-dropdown-item @click="exportData(1, 2)"
              >Export Summary (CSV)</b-dropdown-item
            >
            <b-dropdown-item @click="exportData(2, 1)"
              >Export Click Detail (Excel)</b-dropdown-item
            >
            <b-dropdown-item @click="exportData(2, 2)"
              >Export Click Detail (CSV)</b-dropdown-item
            >
          </b-dropdown>
        </CCol>
      </CRow>
      <b-sidebar
        id="sidebar-1"
        title="FILTER"
        backdrop
        shadow
        backdrop-variant="dark"
        right
        ref="filterSidebar"
      >
        <div class="px-3 py-2">
          <div class="text-right">
            <button
              type="button"
              class="btn btn-link px-0"
              @click="onClearFilter()"
            >
              Clear
            </button>
          </div>
          <div class="row mt-2">
            <div class="col-6">
              <p class="font-weight-bold my-2">Start Date</p>
              <datetime
                v-model="filter.startFilterDate"
                placeholder="Please select date"
                class="date-filter"
                format="dd MMM yyyy"
              ></datetime>
            </div>
            <div class="col-6">
              <p class="font-weight-bold my-2">End Date</p>
              <datetime
                v-model="filter.endFilterDate"
                placeholder="Please select date"
                class="date-filter"
                format="dd MMM yyyy"
              ></datetime>
            </div>
          </div>
          <div class="text-center mt-3">
            <button
              type="button"
              class="btn btn-main button"
              @click="handleFilterDate()"
            >
              Submit
            </button>
          </div>
        </div>
      </b-sidebar>

      <div
        class="no-gutters bg-white-border"
        v-bind:class="$isLoading ? 'd-none' : 'd-block'"
      >
        <div class="px-4 px-sm-5 py-4">
          <p class="text-gray">
            Check the number of times rich menus have benn shown and the number
            of clicks
          </p>
          <hr />
          <div>
            <HeaderLine
              text="Selected rich menu"
              class="mb-3"
              :isLine="false"
            />
          </div>
          <div class="">
            <label class="font-weight-bold">Title :</label>
            <label class="ml-3">{{ data.name }}</label>
          </div>

          <b-card class="rounded-0 mt-3">
            <b-row class="">
              <b-col>
                <div class="rich-menu-panel">
                  <div class="rich-menu">
                    <b-img :src="data.imageUri" ref="menu-image"></b-img>
                    <div class="menu">
                      <div
                        v-for="cell of lineRichTemplateAction"
                        :key="cell.id"
                        class="menu-box"
                        :style="{
                          top: `${cell.top}%`,
                          left: `${cell.left}%`,
                          width: `${cell.widthPercent}%`,
                          height: `${cell.heightPercent}%`,
                        }"
                      >
                        <div class="sort-menu">
                          {{ convertText(cell.sort) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col
                ><div
                  v-for="cell of lineRichTemplateAction"
                  :key="cell.id"
                  class="menu-box ml-4 mt-3"
                >
                  <div class="sort-menu font-weight-bold">
                    {{ convertText(cell.sort) }} :
                    {{ data.actions[cell.sort - 1].displayText }}
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>

          <b-card class="rounded-0"
            ><canvas id="click-chart" height="100"></canvas
          ></b-card>

          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="data.report"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(date)="{ item }">
              <div :class="{ 'text-success': item.date == 'Total' }">
                {{ item.date }}
              </div>
            </template>
            <template v-slot:cell(totalClickDisplay)="{ item, index }">
              <b-row class="">
                <b-col
                  class="d-flex justify-content-end align-items-center p-0"
                  >{{ item.totalClickDisplay }}</b-col
                >
                <b-col class="p-0">
                  <div
                    v-for="action in item.actions"
                    class=""
                    :key="action.sort"
                  >
                    {{ action.actionName }} {{ action.totalClickDisplay }}
                  </div>
                </b-col>
              </b-row>
            </template>

            <template v-slot:cell(totalUniqueClickDisplay)="{ item, index }">
              <b-row class="">
                <b-col
                  class="d-flex justify-content-end align-items-center p-0"
                  >{{ item.totalUniqueClickDisplay }}</b-col
                >
                <b-col class="p-0">
                  <div
                    v-for="action in item.actions"
                    class=""
                    :key="action.sort"
                  >
                    {{ action.actionName }} {{ action.totalUniqueClickDisplay }}
                  </div>
                </b-col>
              </b-row>
            </template>

            <template v-slot:cell(totalClickRateDisplay)="{ item, index }">
              <b-row class="">
                <b-col
                  class="d-flex justify-content-end align-items-center p-0"
                  >{{ item.totalClickRateDisplay }}</b-col
                >
                <b-col class="p-0">
                  <div
                    v-for="action in item.actions"
                    class=""
                    :key="action.sort"
                  >
                    {{ action.actionName }} {{ action.totalClickRateDisplay }}
                  </div>
                </b-col>
              </b-row>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
          <b-row>
            <b-col
              class="form-inline justify-content-center justify-content-sm-between"
            >
              <div class="d-sm-flex mt-2">
                <b-pagination
                  v-model="filter.page"
                  :total-rows="rows"
                  :per-page="filter.pageSize"
                  class="m-md-0"
                  @change="pagination"
                  align="center"
                ></b-pagination>

                <div class="ml-2">
                  <p class="total-record-paging text-nowrap text-center">
                    {{ totalRowMessage }}
                  </p>
                </div>
              </div>

              <b-form-select
                v-model="filter.pageSize"
                @change="handleChangeTake"
                :options="pageOptions"
              ></b-form-select>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>

    <b-modal ref="loadingModal" hide-header hide-footer centered>
      <div class="text-center">
        <img
          src="@/assets/images/icons/loading.svg"
          alt="loading"
          class="mb-3 w-25"
        />
        <h1 class="font-weight-bold text-modal">
          In progress. Exporting Data...
        </h1>
      </div>
    </b-modal>
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import HeaderLine from "@/components/HeaderLine";
import Chart from "chart.js/auto";
import axios from "axios";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";

export default {
  components: {
    HeaderLine,
    Chart,
    ModalAlertError,
  },

  data() {
    return {
      data: {},
      isLoading: true,
      filter: {
        startFilterDate: "",
        endFilterDate: "",
        page: 1,
        pageSize: 10,
        type: 0,
      },
      isBusy: false,
      fields: [
        {
          key: "date",
          label: "Date",
          thClass: "w-2 ",
        },
        {
          key: "totalClickDisplay",
          label: "Clicks",
          thClass: "w-1",
          tdClass: "w-1",
        },
        {
          key: "totalUniqueClickDisplay",
          label: "Unique Clicks",
          thClass: "w-1",
          tdClass: "w-1",
        },
        {
          key: "totalClickRateDisplay",
          label: "Click Rate",
          thClass: "w-1",
          tdClass: "w-1",
        },
      ],
      id: this.$route.params.id,
      templateOptions: [],
      modalMessage: "",
      isDupe: false,
      tabIndex: 0,
      form: {},
      lineRichTemplateAction: [],
      chartData: {
        labels: [], // เดือนหรือช่วงเวลา
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
            display: true,
          },
          tooltip: {
            enabled: true,
          },
        },
        scales: {
          x: {
            stacked: true, // เปิดใช้งาน stacked column
            title: {
              display: false,
              text: "Date",
            },
          },
          y: {
            stacked: true, // เปิดใช้งาน stacked column
            title: {
              display: true,
              text: "Total Clicks",
            },
            beginAtZero: true,
          },
        },
      },
      // ตั้งค่าการทำงานให้เป็น stacked bar
      indexAxis: "x",
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
      totalRowMessage: "",
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      rows: 0,
      error: false,
    };
  },
  async created() {
    await this.getTemplate();
    await this.getDetail();

    await this.getReport();
  },
  methods: {
    async getReport() {
      if (window.myChart) {
        window.myChart.destroy();
      }

      this.$isLoading = true;

      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 30);

      this.filter.startFilterDate = this.filter.startFilterDate
        ? this.filter.startFilterDate
        : thirtyDaysAgo;

      this.filter.endFilterDate = this.filter.endFilterDate
        ? this.filter.endFilterDate
        : today;

      let payload = {
        LineRichId: this.id,
        startFilterDate: this.filter.startFilterDate,
        endFilterDate: this.filter.endFilterDate,
        page: this.filter.page,
        pageSize: this.filter.pageSize,
        type: this.filter.type + 1,
      };
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/customer/report/RichMenu`,
        null,
        this.$headers,
        payload
      );

      if (resData.result == 1) {
        this.data = resData.detail;
        this.onChangeTemplate(this.form.lineRichTemplateId, true);

        const ctx = document.getElementById("click-chart").getContext("2d");

        this.chartData.labels = resData.detail.report.map((x) => x.date);
        let ChartDataLabels = resData.detail.report[0].actions.map(
          (x) => x.actionName
        );
        this.chartData.datasets = resData.detail.report[0].actions.map(
          (action, index) => {
            return {
              label: ChartDataLabels[index],
              data: resData.detail.report.map((report) => {
                const actionData = report.actions.find(
                  (a) => a.actionName === action.actionName
                );
                return actionData.totalClick;
              }),
              backgroundColor: this.getRandomColor(),
              // borderColor: this.getRandomColor(),
              borderWidth: 1,
            };
          }
        );

        window.myChart = new Chart(ctx, {
          type: "bar", // ประเภทของกราฟ (Bar Chart)
          data: this.chartData,
          options: this.chartOptions,
        });
        this.rows = resData.detail.totalData;
        if (resData.detail.report.length > 0) {
          let numRecordFirst = this.filter.page * this.filter.pageSize;
          console.log(numRecordFirst);
          numRecordFirst = numRecordFirst - this.filter.pageSize + 1;
          let numRecordLast =
            this.filter.page * this.filter.pageSize > resData.detail.count
              ? resData.detail.totalData
              : this.filter.page * this.filter.pageSize;
          this.totalRowMessage =
            "Showing " +
            numRecordFirst +
            " - " +
            numRecordLast +
            " of " +
            resData.detail.totalData +
            " entries";
        } else {
          this.totalRowMessage = "";
        }
      }
      console.log(window.myChart);

      this.$isLoading = false;
    },
    getRandomColor() {
      // กำหนดชุดสีคงที่ 12 สี
      const fixedColors = [
        "#F47306 ",
        "#80C141 ",
        "#5B57A5 ",
        "#FDC657 ",
        "#6FA0DB ",
    
        "#EEF6E8 ",
        "#578E7E",
        "#8174A0",
        "#C30E59",
        "#6C4E31",
        "#36BA98",
        "#49243E",
        "#FFBE98",
      ];

      if (this.colorIndex === undefined) {
        this.colorIndex = 0;
      }

      const color = fixedColors[this.colorIndex];
      this.colorIndex = (this.colorIndex + 1) % fixedColors.length;

      return color;
    },
    async getTemplate() {
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/lineRich/LineRichTemplate`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.templateOptions = resData.detail;
      }
    },
    async getDetail() {
      this.$isLoading = true;

      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/lineRich/${this.id}`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.form = resData.detail;
      }

      this.$isLoading = false;
    },
    onChangeTemplate(val, flag = false) {
      this.isLoading = true;
      let template = this.templateOptions.find((el) => el.id == val);

      function percentage(partialValue, totalValue) {
        return (100 * partialValue) / totalValue;
      }

      this.lineRichTemplateAction = template.lineRichTemplateAction.map(
        (el) => {
          return {
            ...el,
            top: percentage(el.y, template.height),
            left: percentage(el.x, template.width),
            widthPercent: percentage(el.width, template.width),
            heightPercent: percentage(el.height, template.height),
          };
        }
      );

      this.$nextTick();
      this.getRatioImage();

      if (!flag)
        this.form.lineRichAction = template.lineRichTemplateAction.map((el) => {
          return {
            actionType: "",
            lineRichTemplateActionId: el.id,
            label: null,
            text: null,
            uri: null,
            richMenuAlias: null,
            sort: el.sort,
          };
        });
      this.isLoading = false;
    },
    convertText(a) {
      let Alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      return Alphabet[a - 1];
    },
    getRatioImage() {
      return this.$refs["menu-image"];
    },
    // async highlight(id) {
    //   this.form.highlight = id;
    //   this.form.lineRichTemplateAction = {
    //     ...this.form.lineRichTemplateAction,
    //   };

    //   await this.$nextTick();
    //   this.$refs["action-" + id][0].$children[0].focus();
    // },
    handleChangeTake(value) {
      this.filter.take = value;
      this.getReport();
    },
    pagination(page) {
      this.filter.page = page;
      this.getReport();
    },

    handleFilterDate() {
      this.filter.page = 1;
      this.getReport();
    },
    onClearFilter() {
      this.filter.page = 1;
      this.filter.startFilterDate = "";
      this.filter.endFilterDate = "";
      this.filter.pageName = [];
      this.error = false;
      this.getRepirt();
    },
    exportData: async function (type, flagFileType) {
      this.$refs.loadingModal.show();
      let fileType = flagFileType === 2 ? ".csv" : ".xlsx";
      this.fileTypeId = flagFileType;
      axios({
        url: `${this.$baseUrl}/api/customer/export/RichMenu`,
        method: "post",
        headers: this.$headers,
        responseType: "blob",
        data: {
          LineRichId: this.id,
          fileTypeId: flagFileType,
          startFilterDate: this.filter.startFilterDate,
          endFilterDate: this.filter.endFilterDate,
          page: this.filter.page,
          pageSize: this.filter.pageSize,
          type: type,
        },
      })
        .then((response) => {
          this.$refs.loadingModal.hide();
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          var dateFile = this.$moment().format("DDMMYYYYhhmmss");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `Report ` + "Audit" + `-` + dateFile + fileType
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          this.$refs.loadingModal.hide();
          this.modalMessage = "ไม่สามารถ Export ข้อมูลได้";
          this.$refs.modalAlertError.show();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-link {
  text-decoration: underline;
}
.rich-menu {
  overflow: hidden;
  position: relative;
}

.layout {
  width: 100%;
  /* border-radius: 0.75rem; */
  position: relative;
  overflow: hidden;
  height: 100%;
  aspect-ratio: 9 / 16;
  /*
  background: gray; */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: whitesmoke;
  /* justify-content: space-between; */
}
.menu {
  > div {
    position: absolute;
    top: 0;
    border: 2px solid green;
    height: auto;
    display: flex;

    align-items: center;
    justify-content: center;
    width: 100%;
    &.highlight {
      background: rgba(0, 128, 0, 0.329);
    }
  }
  .sort-menu {
    width: auto;
    color: white;
    font-size: 42px;
    font-weight: bold;
    position: absolute;
    // top: calc(124px + -75%);
    // left: calc(185px - 25%);
    // transform: translate();
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }
}

.rich-menu-panel {
  width: 100%;
  img {
    width: 100%;
  }
}
</style>
