var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('div',[_c('InputSelect',{attrs:{"title":"Template","options":_vm.templateOptions,"valueField":"id","textField":"name","placeholder":"Template","isRequired":"","isValidate":_vm.$v.form.lineRichTemplateId.$error,"v":_vm.$v.form.lineRichTemplateId,"disabled":_vm.id != 0},on:{"onDataChange":_vm.onChangeTemplate},scopedSlots:_vm._u([{key:"option-first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(" -- Select Template -- ")])]},proxy:true}]),model:{value:(_vm.form.lineRichTemplateId),callback:function ($$v) {_vm.$set(_vm.form, "lineRichTemplateId", $$v)},expression:"form.lineRichTemplateId"}})],1),_c('InputText',{staticClass:"mt-3",attrs:{"type":"text","textFloat":"Chat Bar Text","placeholder":"Enter Text","maxLength":14,"disabled":_vm.id != 0},on:{"blur":_vm.onChatTextEmpty},model:{value:(_vm.form.chatBarText),callback:function ($$v) {_vm.$set(_vm.form, "chatBarText", $$v)},expression:"form.chatBarText"}}),_c('div',[_c('UploadFile',{attrs:{"checkRegRatio":true,"maxSize":1000000,"textFloat":"Thumbnail","placeholder":"Please Select a File To Upload.","format":"image","name":"thumbnail","text":"*Please upload only file .png, .jpg ratio more than 1:45 and size less than 1 MB.","fileName":_vm.form.imageUri,"value":_vm.form.imageUri,"isRequired":"","isValidate":_vm.$v.form.imageUri.$error,"v":_vm.$v.form.imageUri,"disabled":_vm.id > 0},on:{"onFileChange":_vm.onImageChange,"delete":_vm.deleteImage},model:{value:(_vm.form.imageUri),callback:function ($$v) {_vm.$set(_vm.form, "imageUri", $$v)},expression:"form.imageUri"}})],1),_c('b-row',{staticClass:"preview-img-container m-0"},[_c('b-col',{staticClass:"preview-box square-ratio bank-img",style:({
              'background-image': 'url(' + _vm.form.imageUri + ')',
            }),attrs:{"cols":"3"}},[(_vm.isLoadingImage)?_c('img',{staticClass:"loading",attrs:{"src":require("@/assets/images/icons/loading.svg"),"alt":"loading"}}):_vm._e()]),_c('b-col',[_c('li',{staticClass:"mt-1"},[_vm._v("Image Format JPEG or PNG")]),_c('li',{staticClass:"mt-1"},[_vm._v("Image width: 800 to 2500 pixels")]),_c('li',{staticClass:"mt-1"},[_vm._v("Image height: 250 pixels or more")]),_c('li',{staticClass:"mt-1"},[_vm._v(" Image aspext ratio (width/height): 1.45 or more ")]),_c('li',{staticClass:"mt-1"},[_vm._v("Max file size: 1 MB")])])],1),(_vm.form.lineRichAction.length > 0)?_c('div',{staticClass:"mt-3"},[_c('label',{staticClass:"select-custom"},[_vm._v("Action")]),_vm._l((_vm.form.lineRichAction),function(action,index){return [_c('div',{key:action.id},[_c('div',{staticClass:"d-flex align-items-center w-100",class:{ 'mt-3': index !== 0 }},[_c('div',{staticClass:"col-1"},[_vm._v(" "+_vm._s(_vm.convertText(action.sort))+" ")]),_c('InputSelect',{ref:'action-' + action.sort,refInFor:true,staticClass:"w-100",attrs:{"name":'action-' + action.sort,"title":"Action Type","options":_vm.actionType,"isValidate":_vm.$v.form.lineRichAction.$each.$iter[index].actionType
                      .$error,"v":_vm.$v.form.lineRichAction.$each.$iter[index].actionType,"disabled":_vm.id != 0},on:{"onDataChange":(val) => _vm.onActionChange(val, index)},scopedSlots:_vm._u([{key:"option-first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"","disabled":""}},[_vm._v("-- Select Action Type --")])]},proxy:true}],null,true),model:{value:(action.actionType),callback:function ($$v) {_vm.$set(action, "actionType", $$v)},expression:"action.actionType"}})],1),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"w-100"},[(action.actionType == 'Uri')?_c('InputText',{attrs:{"type":"text","textFloat":"","placeholder":"Enter Text","isValidate":_vm.$v.form.lineRichAction.$each.$iter[index].uri.$error,"v":_vm.$v.form.lineRichAction.$each.$iter[index].uri,"disabled":_vm.id != 0},model:{value:(action.uri),callback:function ($$v) {_vm.$set(action, "uri", $$v)},expression:"action.uri"}}):(action.actionType == 'Message')?_c('InputTextArea',{attrs:{"textFloat":"","placeholder":"Enter Text","rows":"4","maxLength":300,"isValidate":_vm.$v.form.lineRichAction.$each.$iter[index].text.$error,"v":_vm.$v.form.lineRichAction.$each.$iter[index].text,"disabled":_vm.id != 0},model:{value:(action.text),callback:function ($$v) {_vm.$set(action, "text", $$v)},expression:"action.text"}}):(action.actionType == 'RichMenuSwitch')?_c('InputSelect',{staticClass:"w-100 mt-3",attrs:{"name":'RichMenuSwitch' + index,"title":"","options":_vm.optionsList,"isValidate":_vm.$v.form.lineRichAction.$each.$iter[index].richMenuAlias
                        .$error,"v":_vm.$v.form.lineRichAction.$each.$iter[index].richMenuAlias,"valueField":"richMenuAlias","textField":"name","disabled":_vm.id != 0},scopedSlots:_vm._u([{key:"option-first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- Select Rich Menu --")])]},proxy:true}],null,true),model:{value:(action.richMenuAlias),callback:function ($$v) {_vm.$set(action, "richMenuAlias", $$v)},expression:"action.richMenuAlias"}}):_vm._e()],1)])])]})],2):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","lg":"8","xl":"6"}},[_c('div',{staticClass:"mb-1 select-custom"},[_vm._v("Preview")]),_c('div',{staticClass:"preview-container"},[_c('div',{staticClass:"wrap-panel"},[_c('div',{staticClass:"layout"},[_c('div',{staticClass:"rich-menu-panel"},[_c('b-collapse',{attrs:{"id":"my-collapse","visible":""}},[(!_vm.isLoading)?_c('div',{staticClass:"rich-menu"},[_c('b-img',{ref:"menu-image",attrs:{"src":_vm.form.imageUri || _vm.form.default_image_layout}}),_c('div',{staticClass:"menu"},_vm._l((_vm.form.lineRichTemplateAction),function(cell){return _c('div',{key:cell.id,class:`menu-box ${
                          _vm.form.highlight == cell.sort ? 'highlight' : ''
                        }`,style:({
                          top: `${cell.top}%`,
                          left: `${cell.left}%`,
                          width: `${cell.widthPercent}%`,
                          height: `${cell.heightPercent}%`,
                        }),on:{"click":function($event){return _vm.highlight(cell.sort)}}},[_c('div',{staticClass:"sort-menu"},[_vm._v(" "+_vm._s(_vm.convertText(cell.sort))+" ")])])}),0)],1):_vm._e()]),_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle:my-collapse",arg:"my-collapse"}],staticClass:"rich-menu-bottom"},[_c('font-awesome-icon',{attrs:{"icon":"bars"}}),_c('div',{staticClass:"mx-auto"},[_vm._v(" "+_vm._s(_vm.form.chatBarText)+" "),_c('font-awesome-icon',{attrs:{"icon":"chevron-down"}})],1)],1)],1)])])])])],1)],1),_c('ModalAlertError',{ref:"modalAlertError",attrs:{"text":_vm.modalMessage}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }