<template>
  <div>
    <form class="form-box">
      <b-container class="container-box">
        <b-row class="no-gutters">
          <b-col>
            <h1 class="font-weight-bold text-uppercase">Report Note Code</h1>
          </b-col>
        </b-row>
        <b-row
          class="no-gutters bg-white-border mt-4"
          v-if="items"
          v-bind:class="$isLoading ? 'd-none' : 'd-block'"
        >
          <b-col class="px-4 px-sm-5 py-4">
            <div class="mb-3" v-for="(item, i) in items" :key="i">
              <div class="position-relative">
                <b-button
                  v-b-toggle
                  :href="`#collapse-${i}`"
                  @click.prevent
                  variant="primary"
                  class="seo-btn"
                  >{{ item.header }}</b-button
                >
                <font-awesome-icon icon="sort-down" class="arrow-logo r-15" />
              </div>
              <b-collapse visible :id="`collapse-${i}`" class="mt-2">
                <b-card>
                  <b-card-text>
                    {{ item.message }}
                  </b-card-text>
                  <button
                    type="button"
                    class="btn btn-edit md-2 text-uppercase"
                    @click="setEditText(item)"
                    :disabled="isDisable"
                  >
                    Edit
                  </button>
                </b-card>
              </b-collapse>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </form>

    <!-- Modal Result -->
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <b-modal
      ref="editTextValue"
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
      size="lg"
    >
      <div class="modal-header border-0 px-0 pt-0">
        <h3 class="font-weight-bold">{{ modalHeaders }}</h3>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="$refs['editTextValue'].hide()"
        >
          ×
        </button>
      </div>
      <div>
        <b-container>
          <div>
            <b-button
              v-for="(variable, i) in variableItem"
              :key="i"
              class="btn-color px-3 mb-3 mr-3"
              variant="outline"
              @click="AddVariable(variable)"
              >{{ variable.name }}</b-button
            >
          </div>
          <div>
            <b-form-textarea
              ref="textarea"
              v-model="modalEditValue"
              placeholder="Enter something..."
              rows="10"
            ></b-form-textarea>
          </div>
          <!-- <div v-if="$v.modalEditValue && $v.modalEditValue.$error">
            <span class="text-error" v-if="$v.modalEditValue.required == false">This field can’t be empty</span>
          </div> -->
          <b-row>
            <b-col md="6" class="text-left pb-3 mt-4">
              <b-button
                class="cancel-btn-color px-4"
                variant="outline"
                @click="Cancel"
                >Cancel</b-button
              >
            </b-col>
            <b-col md="6" class="text-right pb-3 mt-4">
              <b-button
                class="btn-edit-color px-4"
                variant="outline"
                @click="Edit()"
                >Save</b-button
              >
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import { required } from "vuelidate/lib/validators";

export default {
  name: "reportnotecode",
  components: {
    ModalAlert,
    ModalAlertError,
  },
  data() {
    return {
      img: "",
      isDisable: false,
      isLoading: false,
      isBusy: false,
      modalMessage: "",
      items: null,
      variableData: [],
      variableItem: [],
      modalEditValue: "",
      modalHeaders: "",
      form: {
        id: null,
        noteCodeTypeId: null,
        message: "",
      },
    };
  },
  validations: {
    modalEditValue: { required },
  },
  created: async function () {
    await this.getData();
  },
  methods: {
    getData: async function () {
      this.$isLoading = true;
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/NoteCode/GetTemplateNoteCodeReportList`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        // console.log('resData', resData);
        for (let i = 0; i < resData.detail.length; i++) {
          if (resData.detail[i].noteCodeTypeId === 1) {
            resData.detail[i].header = "Report Register Line Connect";
          } else if (resData.detail[i].noteCodeTypeId === 2) {
            resData.detail[i].header = "Report Installment Information";
          } else if (resData.detail[i].noteCodeTypeId === 3) {
            resData.detail[i].header = "Report Barcode/QRCode";
          } else if (resData.detail[i].noteCodeTypeId === 4) {
            resData.detail[i].header = "Report Online Payment";
          } else if (resData.detail[i].noteCodeTypeId === 5) {
            resData.detail[i].header = "Register Change Line Connect";
          }
        }
        this.items = resData.detail;
        this.$isLoading = false;
      }
      this.getNoteCodeVariable();
    },
    async getNoteCodeVariable() {
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/NoteCode/GetTemplateNoteCodeReportVariable`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.variableData = resData.detail;
      }
    },
    setEditText(value) {
      // console.log('value', value);
      this.form.id = value.id;
      this.form.noteCodeTypeId = value.noteCodeTypeId;
      this.modalEditValue = value.message;
      if (value.noteCodeTypeId === 1) {
        this.modalHeaders = "Report Register Line Connect";
      } else if (value.noteCodeTypeId === 2) {
        this.modalHeaders = "Report Installment Information";
      } else if (value.noteCodeTypeId === 3) {
        this.modalHeaders = "Report Barcode/QRCode";
      } else if (value.noteCodeTypeId === 4) {
        this.modalHeaders = "Report Online Payment";
      } else if (value.noteCodeTypeId === 5) {
        this.modalHeaders = "Report Installment Information";
      }
      for (let i = 0; i < this.variableData.length; i++) {
        if (value.noteCodeTypeId === this.variableData[i].noteCodeTypeId) {
          this.variableItem = this.variableData[i].detail;
        }
      }
      this.$refs["editTextValue"].show();
    },
    AddVariable(variable) {
      const insertText = variable.name;
      if (!insertText.length) return;
      const textarea = this.$refs.textarea;
      const sentence = textarea.value;
      const len = sentence.length;
      let pos = textarea.selectionStart;
      if (pos === undefined) {
        pos = 0;
      }
      const before = sentence.substr(0, pos);
      const after = sentence.substr(pos, len);
      this.modalEditValue = before + `{{${insertText}}}` + after;
      this.$nextTick().then(() => {
        textarea.selectionStart = pos + insertText.length;
      });
    },
    Cancel() {
      this.$refs["editTextValue"].hide();
    },
    async Edit() {
      this.form.message = this.modalEditValue;
      // console.log('form ===', this.form);
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/NoteCode/EditTemplateNoteCodeReportList`,
        null,
        this.$headers,
        this.form
      );
      if (resData.result === 1) {
        this.$refs["editTextValue"].hide();
        this.modalMessage = "แก้ไขข้อมูลเรียบร้อย";
        this.$refs.modalAlert.show();
        this.getData();
      }
    },
  },
};
</script>
<style scoped>
.btn-edit {
  background-color: #80c141 !important;
  color: #ffffff !important;
  font-weight: bold;
}
.btn-color {
  color: #80c141;
  border-color: #80c141;
}
.btn-color:hover {
  color: #ffffff;
  background-color: #80c141;
  border-color: #80c141;
}
.cancel-btn-color {
  border-color: #000000;
}
.btn-edit-color {
  color: #ffffff;
  border-color: #80c141;
  background-color: #80c141 !important;
}
</style>
