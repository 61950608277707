<template>
  <div>
    <div class="min-vh-100">
       <CRow class="no-gutters px-3 px-sm-0">
        <CCol cols="6">
          <h1 class="mr-sm-4 header">Customer Wait Verify </h1>
        </CCol>
        <CCol cols="6" class="text-right"></CCol>
      </CRow>
      <div class="bg-white-border px-4 px-sm-5 mt-3 py-4">
        <b-row class="no-gutters justify-content-between">
           <b-col md="5" class="px-0 py-3 pt-lg-2">
            <b-input-group class="panel-input-serach">
              <b-form-input
                class="input-serach"
                placeholder="Customer Name, Mobile No, ID Card, Line Name"
                v-model="filter.searchText"
                @keyup="handleSearch"
              ></b-form-input>
              <b-input-group-prepend @click="btnSearch">
                <span class="icon-input m-auto pr-2">
                  <font-awesome-icon icon="search" title="View" />
                </span>
              </b-input-group-prepend>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table
              responsive
              class="text-center"
              striped
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(customerName)="data">
                <div v-if="data.item.customerName == null">- -</div>
                <div v-else>{{data.item.customerName}}</div>
              </template>
              <template v-slot:cell(mobileNumber)="data">
                <div v-if="data.item.mobileNumber == null">-</div>
                <div v-else>{{data.item.mobileNumber}}</div>
              </template>
              <template v-slot:cell(contractNo)="data">
                <div v-if="data.item.contractNo == null">-</div>
                <div v-else>{{data.item.contractNo}}</div>
              </template>
              <template v-slot:cell(birthDay)="data">
                <div v-if="data.item.birthDay == null">-</div>
                <div v-else>
                  <span>{{ data.item.birthDay | moment($formatDate) }}</span>
                </div>
              </template>
              <template v-slot:cell(idCard)="data">
                <div v-if="data.item.idCard == null">-</div>
                <div v-else>{{data.item.idCard}}</div>
              </template>
              <template v-slot:cell(status)="data">
                <div v-if="data.item.status == null">-</div>
                <div v-else>{{data.item.status}}</div>
              </template>
              <template v-slot:cell(action)="data">
                <b-button variant="link" class="px-1 py-0">
                  <!-- <router-link :to="'/user/details/'+ data.item.lineId">
                    <font-awesome-icon icon="pencil-alt" class="text-warning" title="View" />
                  </router-link> -->
                </b-button>
        
                <b-button variant="link" class="px-1 py-0">
                  <font-awesome-icon
                    icon="trash-alt"
                    class="text-danger"
                    @click="openModalDelete(data.item)"
                  />
                </b-button>
              </template>
              <template v-slot:cell(createdTime)="data">
              <span>{{
                new Date(data.item.createdTime) | moment("DD MMM YYYY HH:mm")
              }}</span>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="form-inline justify-content-center justify-content-sm-between">
            <div class="d-sm-flex mt-2">
              <b-pagination
                v-model="filter.page"
                :total-rows="rows"
                :per-page="filter.pageSize"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>

              <div class="ml-2">
                <p class="total-record-paging text-nowrap text-center">{{totalRowMessage}}</p>
              </div>
            </div>

            <b-form-select
              v-model="filter.pageSize"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
    </div>
    <!-- Modal -->
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="Delete"
      colorBtnConfirm="danger"
      btnCancel="Close"
      ref="ModalAlertConfirm"
      @confirm="btnDelete"
    />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
export default {
  name: "user",
  components: {
    InputSelect,
    ModalAlertConfirm,
    ModalAlert,
    ModalAlertError
  },
  data() {
    return {
      statusList: [],
      modalMessage: "",
      requestDeleteUser: {
        LineId: null,
        IdCard: null,
      },
      fields: [
        {
          key: "lineName",
          label: "Line Name",
          class: "w-100px text-nowrap"
        },
        {
          key: "lineId",
          label: "Line ID",
          class: "w-100px text-nowrap"
        },
        {
          key: "createdTime",
          label: "Transaction Date",
          class: "w-100px text-nowrap"
        },
        {
          key: "customerName",
          label: "Customer Name",
          class: "w-100px text-nowrap"
        },
        {
          key: "mobileNumber",
          label: "Mobile No",
          class: "w-100px text-nowrap"
        },
        {
          key: "contractUnderId",
          label: "Contract under ID",
          class: "w-100px text-nowrap"
        },
        { key: "birthDay", label: "Birthday", class: "w-100px text-nowrap" },
        { key: "idCard", label: "ID Card", class: "w-100px text-nowrap" },
 
        { key: "action", label: "Action", class: "w-100px text-nowrap" }
      ],
      items: [],
      isBusy: true,
      rows: 0,
      filter: {
        searchText: "",
        statusId: -1,
        page: 1,
        pageSize: 10
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" }
      ],
      totalRowMessage: ""
    };
  },
  created: async function() {
    this.$isLoading = true;
    await this.getStatusList();
    await this.getList();
    this.$isLoading = false;
  },
  methods: {
    getStatusList: async function() {
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Customer/index`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        resData.detail.statusList.map(itemDetail => {
          let itemArray = { value: itemDetail.id, text: itemDetail.name };
          this.statusList.push(itemArray);
        });
      }
    },
    getList: async function() {
      this.isBusy = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Customer/AllListCustomerWaitingVerify`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        this.items = resData.detail.data;
        this.rows = resData.detail.totalData;
        if (resData.detail.totalData > 0) {
          let numRecordFirst = this.filter.page * this.filter.pageSize;
          numRecordFirst = numRecordFirst - this.filter.pageSize + 1;
          let numRecordLast =
            this.filter.page * this.filter.pageSize > resData.detail.totalData
              ? resData.detail.totalData
              : this.filter.page * this.filter.pageSize;
          this.totalRowMessage =
            "Showing " +
            numRecordFirst +
            " - " +
            numRecordLast +
            " of " +
            resData.detail.totalData +
            " entries";
        }

        this.isBusy = false;
      }
    },
    pagination(Page) {
      this.filter.page = Page;
      this.getList();
    },
    hanndleChangePerpage(value) {
      this.filter.page = 1;
      this.filter.pageSize = value;
      this.getList();
    },
    handleSearch(e) {
      if (e.keyCode === 13) {
        this.filter.page = 1;
        this.getList();
      }
    },
    btnSearch() {
      this.filter.page = 1;
      this.getList();
    },
    hanndleStatusList(value) {
      this.filter.page = 1;
      this.filter.statusId = value;
      this.getList();
    },
    openModalDelete(value) {
      this.requestDeleteUser.LineId = value.lineId;
      this.requestDeleteUser.IdCard = value.idCard;
      this.modalMessage = "คุณต้องการลบ" + value.customerName + "ใช่หรือไม่?";
      this.$refs.ModalAlertConfirm.show();
    },
    btnDelete: async function() {
      this.$refs.ModalAlertConfirm.hide();
      let resData = await this.$callApi(
        "delete",
        `${this.$baseUrl}/api/Customer/DeleteCustomerWaitingVerify`,
        null,
        this.$headers,
        this.requestDeleteUser
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        this.filter.page = 1;
        await this.getList();
      } else {
        this.$refs.modalAlertError.show();
      }
    }
  }
};
</script>       

<style scoped>
</style>