<template>
    <div>
      <CRow class="no-gutters px-3 px-sm-0">
        <CCol cols="8">
          <h1 class="mr-sm-4 header">History</h1>
        </CCol>
        <!-- <CCol cols="4" class="text-right">
          <b-button v-b-toggle.sidebar-1 class="btn-filter mr-1">
            <font-awesome-icon
              icon="filter"
              title="filter-btn"
              class="text-white mr-0 mr-sm-1"
            />
            <span class="d-none d-sm-inline">FILTER</span>
          </b-button>
  
          <b-button type="b-button" class="btn btn-main-2 button"
            >CREATE NEW</b-button
          >
        </CCol> -->
      </CRow>
      <!-- <b-sidebar
        id="sidebar-1"
        title="FILTER"
        backdrop
        shadow
        backdrop-variant="dark"
        right
        ref="filterSidebar"
      >
        <div class="px-3 py-2">
          <div class="text-right">
            <button
              type="button"
              class="btn btn-link px-0"
          
            >
              Clear
            </button>
          </div>
          <div class="row mt-2">
            <div class="col-6">
              <p class="font-weight-bold my-2">Start Date</p>
              <datetime
                placeholder="Please select date"
                class="date-filter"
                v-model="filter.startDate"
                format="dd MMM yyyy"
              ></datetime>
            </div>
            <div class="col-6">
              <p class="font-weight-bold my-2">End Date</p>
              <datetime
                placeholder="Please select date"
                class="date-filter"
                v-model="filter.endDate"
                format="dd MMM yyyy"
              ></datetime>
            </div>
          </div>
  
          <p class="text-danger text-center my-3" v-if="error">
            Please enter correct date
          </p>
  
          <div>
            <p class="font-weight-bold my-2">Status</p>
          </div>
  
          <div class="row">
            <div class="col-6" v-for="status in statusList" :key="status.id">
              <div class="form-check mb-2">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="status.id"
                  v-model="filter.documentStatus"
                  checked="checked"
                  :id="'status-' + status.id"
                />
                <label class="form-check-label" :for="'status-' + status.id">{{
                  status.name
                }}</label>
              </div>
            </div>
          </div>
  
          <div class="text-center mt-5">
            <button
              type="button"
              class="btn btn-main button"
              @click="getDataByStatus()"
            >
              Submit
            </button>
          </div>
        </div>
      </b-sidebar> -->
      <div class="bg-white-border-secondary px-4 px-sm-5 mt-3 py-4">
        <b-row class="no-gutters justify-content-between">
          <b-col md="5" class="px-0 py-3 pt-lg-2">
            <!-- <b-input-group class="panel-input-serach">
              <b-form-input
                class="input-serach"
                placeholder="Search Name"
                v-model="filter.search"
                @keyup="filterAction()"
              ></b-form-input>
              <b-input-group-prepend @click="filterAction()">
                <span class="icon-input m-auto pr-2">
                  <font-awesome-icon icon="search" title="View" />
                </span>
              </b-input-group-prepend>
            </b-input-group> -->
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isLoading"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(name)="{ item }">
                <router-link :to="'/rich-menu/' + item.id"
                  ><span class="text-underline">{{
                    item.name
                  }}</span></router-link
                >
              </template>
              <template v-slot:cell(isMember)="{ item, index }">
                <div v-if="item.isMember">
                  <font-awesome-icon
                    icon="check"
                    class="text-success"
                  ></font-awesome-icon>
                </div>
              </template>
              <template v-slot:cell(createdTime)="data">
                <span>{{
                  new Date(data.item.createdTime) | moment("DD MMM YYYY HH:mm")
                }}</span>
                     </template>
            <template v-slot:cell(action)="{ item }">
              <div class="d-flex justify-content-center align-items-center">
                <router-link :to="'/rich-menu/' + item.id">
                  <b-button variant="link" class="text-warning px-1 py-0">
                    <font-awesome-icon icon="eye" title="view" />
                  </b-button>
                </router-link>

                <router-link :to="'/report-richmenu/' + item.id">
                  <b-button variant="link" class="text-warning px-1 py-0">
                    <font-awesome-icon icon="chart-bar" title="view" />
                  </b-button>
                </router-link>
                <!-- <b-button
                  variant="link"
                  class="color-primary px-1 py-0"
                  @click="openModalDelete(item.id)"
                >
                  <font-awesome-icon icon="trash-alt" title="delete" />
                </b-button> -->
              </div>
            </template>
  
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
            <!-- <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          take="pageSize"
          @pagination="pagination"
        /> -->
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="form-inline justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex mt-2">
              
              <b-pagination
                v-model="filter.page"
                :total-rows="rows"
                :per-page="filter.pageSize"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>
  
              <div class="ml-2">
                <p class="total-record-paging text-nowrap text-center">
                  {{ totalRowMessage }} 
                </p>
              </div>
            </div>
  
            <b-form-select
              v-model="filter.pageSize"
              @change="handleChangeTake"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>

      <ModalAlertConfirm
        msg="ยืนยันการลบ ?"
        :text="modalMessage"
        btnConfirm="Delete"
        colorBtnConfirm="danger"
        btnCancel="Close"
        ref="ModalAlertConfirm"
        @confirm="btnDelete"
      />
    </div>
  </template>
  
  <script>

  import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
  
  export default {
    components: { ModalAlertConfirm },
  
    data() {
      return {
        isLoading: false,
        fields: [
          {
            key: "name",
            label: "Name",
            thClass: "w-1"  ,
            tdClass: "text-left",
          },
          {
            key: "lineRichTemplateId",
            label: "Template",
            thClass: "w-1",
          },
          {
            key: "isMember",
            label: "Member",
            thClass: "w-1",
          },
          {
            key: "createdTime",
            label: "Date Time",
            class: "w-1",
          },
          {
            key: "action",
            label: "Action",
            class: "w-1",
          },
        ],
        items: [
          {
            name: "test",
            template: "TRN",
            isMember: "true",
            dateTime: '',
            id: 1,
          },
        ],
        rows: 0,
        pageOptions: [
          { value: 10, text: "10 / page" },
          { value: 30, text: "30 / page" },
          { value: 50, text: "50 / page" },
          { value: 100, text: "100 / page" },
        ],
        filter: {
          search: "",
          status: null,
          isDeualt: null,
          lineRichTemplateId: null,
          page: 1,
          pageSize: 10,
        },
        deleteId: null,
        modalMessage: "",
        totalRowMessage: "",
      };
    },
  
    async mounted() {
      await this.getList();
    },
    watch: {},
    destroyed() {},
    methods: {
      clearValue() {},
      async getList() {
      this.$isLoading = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/lineRich/logs`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result === 1) {
        this.items = resData.detail.data;
        this.rows = resData.detail.count;
        if (resData.detail.data.length > 0) {
          let numRecordFirst = this.filter.page * this.filter.pageSize;
          console.log(numRecordFirst);
          numRecordFirst = numRecordFirst - this.filter.pageSize + 1;
          let numRecordLast =
            this.filter.page * this.filter.pageSize > resData.detail.count
              ? resData.detail.count
              : this.filter.page * this.filter.pageSize;
          this.totalRowMessage =
            'Showing ' +
            numRecordFirst +
            ' - ' +
            numRecordLast +
            ' of ' +
            resData.detail.count +
            ' entries';
        } else {
          this.totalRowMessage = '';
        }
      }
      this.$isLoading = false;
    },
      async filterAction() {
        this.filter.page = 1;
        this.getList();
      },
  
      handleChangeTake(value) {
        this.filter.take = value;
        this.getList();
      },
      pagination(page) {
        this.filter.page = page;
        this.getList();
      },
  
      clearFilter() {
        this.filter = {
          search: "",
          page: 1,
          take: 10,
        };
      },
      openModalDelete(id) {
        this.deleteId = id;
        this.modalMessage = "คุณต้องการลบใช่หรือไม่?";
        this.$refs.ModalAlertConfirm.show();
      },
      btnDelete: async function () {
        console.log(this.deleteId);
        this.$refs.ModalAlertConfirm.hide();
        let resData = await this.$callApi(
          "delete",
          `${this.$baseUrl}/api/lineRich/${this.deleteId}`,
          null,
          this.$headers
          // this.deleteId
        );
        this.modalMessage = resData.message;
        if (resData.result == 1) {
          this.$refs.modalAlert.show();
          this.filter.page = 1;
          await this.getList();
        } else {
          this.$refs.modalAlertError.show();
        }
      },
      // async deleteRichMenu(id) {
      //   this.confirmAlert().then(async ({ value }) => {
      //     if (value) {
      //       this.$isLoading = true
      //       let resData = await this.$callApi(
      //     "delete",
      //     `${this.$baseUrl}/api/lineRich/${id}`,
      //     null,
      //     this.$headers,
      //     this.getDetailRequest
      //   );
      //       this.$isLoading = false
      //       if (resData.data.result) {
      //         this.successAlert();
      //         this.getList();
      //       } else {
      //         this.errorAlert(res.data.message);
      //       }
      //     }
      //   });
      // },
    },
  };
  </script>
  
  <style scoped>
  .icon-size {
    font-size: 20px;
    color: #575757;
  }
  </style>
  