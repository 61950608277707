<template>
  <div>
    <div class="">
      <b-row>
        <b-col md="6">
          <div>
            <InputSelect
              title="Template"
              :options="templateOptions"
              valueField="id"
              textField="name"
              v-model="form.lineRichTemplateId"
              placeholder="Template"
              @onDataChange="onChangeTemplate"
              isRequired
              :isValidate="$v.form.lineRichTemplateId.$error"
              :v="$v.form.lineRichTemplateId"
              :disabled="id != 0"
            >
              <template v-slot:option-first>
                <b-form-select-option :value="null" disabled>
                  -- Select Template --
                </b-form-select-option>
              </template>
            </InputSelect>
          </div>
          <InputText
            type="text"
            textFloat="Chat Bar Text"
            class="mt-3"
            placeholder="Enter Text"
            v-model="form.chatBarText"
            :maxLength="14"
            @blur="onChatTextEmpty"
            :disabled="id != 0"
          />

          <div>
            <UploadFile
              :checkRegRatio="true"
              :maxSize="1000000"
              textFloat="Thumbnail"
              placeholder="Please Select a File To Upload."
              format="image"
              name="thumbnail"
              text="*Please upload only file .png, .jpg ratio more than 1:45 and size less than 1 MB."
              :fileName="form.imageUri"
              v-model="form.imageUri"
              :value="form.imageUri"
              v-on:onFileChange="onImageChange"
              v-on:delete="deleteImage"
              isRequired
              :isValidate="$v.form.imageUri.$error"
              :v="$v.form.imageUri"
              :disabled="id > 0"
            />
          </div>
          <b-row class="preview-img-container m-0">
            <b-col
              cols="3"
              class="preview-box square-ratio bank-img"
              v-bind:style="{
                'background-image': 'url(' + form.imageUri + ')',
              }"
            >
              <img
                src="@/assets/images/icons/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoadingImage"
              />
            </b-col>

            <b-col>
              <li class="mt-1">Image Format JPEG or PNG</li>
              <li class="mt-1">Image width: 800 to 2500 pixels</li>
              <li class="mt-1">Image height: 250 pixels or more</li>
              <li class="mt-1">
                Image aspext ratio (width/height): 1.45 or more
              </li>
              <li class="mt-1">Max file size: 1 MB</li>
            </b-col>
          </b-row>

          <div class="mt-3" v-if="form.lineRichAction.length > 0">
            <label class="select-custom">Action</label>
            <template v-for="(action, index) in form.lineRichAction">
              <div :key="action.id">
                <div
                  class="d-flex align-items-center w-100"
                  :class="{ 'mt-3': index !== 0 }"
                >
                  <div class="col-1">
                    {{ convertText(action.sort) }}
                  </div>

                  <InputSelect
                    :ref="'action-' + action.sort"
                    :name="'action-' + action.sort"
                    class="w-100"
                    title="Action Type"
                    v-model="action.actionType"
                    :options="actionType"
                    :isValidate="
                      $v.form.lineRichAction.$each.$iter[index].actionType
                        .$error
                    "
                    :v="$v.form.lineRichAction.$each.$iter[index].actionType"
                    @onDataChange="(val) => onActionChange(val, index)"
                    :disabled="id != 0"
                  >
                    <template v-slot:option-first>
                      <b-form-select-option value="" disabled
                        >-- Select Action Type --</b-form-select-option
                      >
                    </template>
                  </InputSelect>
                </div>
                <div class="d-flex">
                  <div class="col-1"></div>
                  <div class="w-100">
                    <InputText
                      type="text"
                      textFloat=""
                      placeholder="Enter Text"
                      v-if="action.actionType == 'Uri'"
                      v-model="action.uri"
                      :isValidate="
                        $v.form.lineRichAction.$each.$iter[index].uri.$error
                      "
                      :v="$v.form.lineRichAction.$each.$iter[index].uri"
                      :disabled="id != 0"
                    />
                    <InputTextArea
                      textFloat=""
                      placeholder="Enter Text"
                      rows="4"
                      v-else-if="action.actionType == 'Message'"
                      :maxLength="300"
                      v-model="action.text"
                      :isValidate="
                        $v.form.lineRichAction.$each.$iter[index].text.$error
                      "
                      :v="$v.form.lineRichAction.$each.$iter[index].text"
                      :disabled="id != 0"
                    />
                    <InputSelect
                      :name="'RichMenuSwitch' + index"
                      class="w-100 mt-3"
                      title=""
                      v-model="action.richMenuAlias"
                      :options="optionsList"
                      v-else-if="action.actionType == 'RichMenuSwitch'"
                      :isValidate="
                        $v.form.lineRichAction.$each.$iter[index].richMenuAlias
                          .$error
                      "
                      :v="
                        $v.form.lineRichAction.$each.$iter[index].richMenuAlias
                      "
                      valueField="richMenuAlias"
                      textField="name"
                      :disabled="id != 0"
                    >
                      <template v-slot:option-first>
                        <b-form-select-option :value="null" disabled
                          >-- Select Rich Menu --</b-form-select-option
                        >
                      </template>
                    </InputSelect>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </b-col>
        <b-col cols="12" lg="8" xl="6">
          <div class="mb-1 select-custom">Preview</div>
          <div class="preview-container">
            <div class="wrap-panel">
              <div class="layout">
                <div class="rich-menu-panel">
                  <b-collapse id="my-collapse" visible>
                    <div v-if="!isLoading" class="rich-menu">
                      <b-img
                        :src="form.imageUri || form.default_image_layout"
                        ref="menu-image"
                      ></b-img>
                      <div class="menu">
                     
                        <div
                          v-for="cell of form.lineRichTemplateAction"
                          :key="cell.id"
                          :class="`menu-box ${
                            form.highlight == cell.sort ? 'highlight' : ''
                          }`"
                          @click="highlight(cell.sort)"
                          :style="{
                            top: `${cell.top}%`,
                            left: `${cell.left}%`,
                            width: `${cell.widthPercent}%`,
                            height: `${cell.heightPercent}%`,
                          }"
                        >
                          <div class="sort-menu">
                            {{ convertText(cell.sort) }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <OtherLoading v-else /> -->
                  </b-collapse>
                  <div v-b-toggle:my-collapse class="rich-menu-bottom">
                    <font-awesome-icon icon="bars"></font-awesome-icon>
                    <div class="mx-auto">
                      {{ form.chatBarText }}
                      <font-awesome-icon
                        icon="chevron-down"
                      ></font-awesome-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>
<script>
import InputText from "@/components/inputs/InputText.vue";
import InputSelect from "@/components/inputs/InputSelect.vue";
import UploadFile from "@/components/inputs/UploadFile";
import InputTextArea from "@/components/inputs/InputTextArea";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";

// import PreviewBox from "@/components/PreviewBox.vue";
import { required, requiredIf } from "vuelidate/lib/validators";

const isValidUrl = (string) => {
  try {
    if (string) new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};
export default {
  components: {
    InputText,
    InputSelect,
    UploadFile,
    InputTextArea,
    ModalAlertError,
    // PreviewBox
  },
  props: {
    data: {
      required: true,
    },
    id: {
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      form: {
        id: 0,
        name: "",
        richMenuAlias: "",
        lineRichTemplateId: null,
        lineRichAction: [],
        template: "",
        imageUri: "",
        chatBarText: "Menu",
        lineRichTemplateAction: [],
      },
      showPreview: "",
      isLoadingImage: false,
      templateOptions: [],
      isDisable: false,
      isLoading: false,
      width: "",
      optionsList: [],
      actionType: [],
      modalMessage: "",
    };
  },
  watch: {
    data(newValue) {
      this.form = { ...newValue };
    },
  },
  validations() {
    return {
      form: {
        lineRichTemplateId: { required },
        imageUri: { required },
        lineRichAction: {
          $each: {
            actionType: {
              required,
            },
            richMenuAlias: {
              required: requiredIf(
                (item) => item.actionType == "RichMenuSwitch"
              ),
            },
            text: {
              required: requiredIf((item) => item.actionType == "Message"),
            },
            uri: {
              isValidUrl: requiredIf((item) => item.actionType == "Uri"),
            },
          },
        },
      },
      // form: { imageUri: "" },
    };
  },
  async created() {
    await this.getActionType();
    await this.getTemplate();
  },
  methods: {
    getRatioImage() {
      return this.$refs["menu-image"];
    },
    async getTemplate() {
      // if (this.id > 0) {
      //   this.form = this.data;
      //   console.log(this.data);
      // }
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/lineRich/LineRichTemplate`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.templateOptions = resData.detail;
        if (this.$route.params.id != 0) {
          await this.$nextTick();
          this.onChangeTemplate(this.form.lineRichTemplateId, true);
        }
      }
    },
    onImageChange(img) {
      this.isLoading = true;
      this.isDisable = true;
      var reader = new FileReader();

      reader.readAsDataURL(img);
      reader.onload = async () => {
        let img = new Image();

        img.src = reader.result;
        img.onload = async () => {
          const ratio = img.width / img.height;

          if (ratio < 1.45) {
            this.modalMessage =
              "Please upload an image with a ratio equal to or more than 1:45 !";
            this.$refs.modalAlertError.show();
            this.isLoadingImage = false;
            this.isDisable = false;
          } else if (img.height < 250) {
            this.modalMessage =
              "Please upload an image with a height of at least 250 pixels.";
            this.$refs.modalAlertError.show();
            this.isLoadingImage = false;
            this.isDisable = false;
          } else if (img.width < 800 || img.width > 2500) {
            this.modalMessage =
              "Please upload an image with a width equal to 800 to 2500 pixels.";
            this.$refs.modalAlertError.show();
            this.isLoadingImage = false;
            this.isDisable = false;
          } else {
            this.images = await this.saveImagetoDb(reader.result);
            this.isLoadingImage = false;
            this.isDisable = false;
            this.form.imageUri = this.images;

            this.showPreview = this.form.imageUri;
          }
        };
      };
      this.isLoading = false;
      this.isDisable = false;
    },

    saveImagetoDb: async function (img) {
      var form = {
        resize: true,
        base64: img,
      };

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/image/save`,
        null,
        this.$headers,
        form
      );

      if (data.result == 1) {
        return data.detail.url;
      }
    },
    // saveImagetoDb: async function (img) {

    //   await this.$store.dispatch("uploadProductImage", form);
    //   var data = await this.$store.state.product.stateUploadProductImage;

    //   if (data.result == 1) {
    //     return data.detail;
    //   }
    // },
    deleteImage() {
      this.isLoading = true;
      this.form.imageUri = null;
      this.showPreview = null;
      this.isLoading = false;
    },
    onChangeTemplate(val, flag = false) {
      this.isLoading = true;
      let template = this.templateOptions.find((el) => el.id == val);

      function percentage(partialValue, totalValue) {
        return (100 * partialValue) / totalValue;
      }

      this.form.default_image_layout = template.imageUri || this.form.imageUri ;
      this.form.lineRichTemplateAction = template.lineRichTemplateAction.map(
        (el) => {
          return {
            ...el,
            top: percentage(el.y, template.height),
            left: percentage(el.x, template.width),
            widthPercent: percentage(el.width, template.width),
            heightPercent: percentage(el.height, template.height),
          };
        }
      );
      
      this.$v.form.lineRichAction.$reset();
      this.$nextTick();
      this.getRatioImage();

      if (!flag)
        this.form.lineRichAction = template.lineRichTemplateAction.map((el) => {
          return {
            actionType: "",
            lineRichTemplateActionId: el.id,
            label: null,
            text: null,
            uri: null,
            richMenuAlias: null,
            sort: el.sort,
          };
        });
      this.isLoading = false;
    },
    widthCalculate() {
      this.width = this.$refs["menu-image"].width;
    },
    async highlight(id) {
      this.form.highlight = id;
      console.log(this.form.highlight);
      this.form.lineRichTemplateAction = {
        ...this.form.lineRichTemplateAction,
      };
      if (this.id == 0) {
        await this.$nextTick();
        this.$refs["action-" + id][0].$children[0].focus();
      }
    },
    onActionChange(val, index) {
      console.log(val);
      if (val == "RichMenuSwitch") this.getList();

      this.form.lineRichAction[index].label = null;
      this.form.lineRichAction[index].text = null;
      this.form.lineRichAction[index].uri = null;
      this.form.lineRichAction[index].richMenuAlias = null;
      // this.form.lineRichAction[index] = {
      //   ...this.form.lineRichAction[index],
      //   actionType: val,
      //   label: null,
      //   text: null,
      //   uri: null,
      //   richMenuAlias: null,
      // };
      this.$nextTick();
    },
    onChatTextEmpty(val) {
      if (!val.target.value) this.form.chatBarText = "Menu";
    },
    convertText(a) {
      let Alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      return Alphabet[a - 1];
    },
    async getList() {
      if (this.optionsList.length > 0) return;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/lineRich`,
        null,
        this.$headers,
        {
          search: "",
          status: null,
          isDeualt: null,
          lineRichTemplateId: null,
          page: 1,
          pageSize: 99999,
        }
      );
      if (resData.result === 1) {
        this.optionsList = resData.detail.data;
        this.$nextTick();
      }
    },
    async getActionType() {
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/lineRich/actionType`,
        null,
        this.$headers,
        null
      );
      if (resData.result === 1) {
        this.actionType = resData.detail;
        this.$nextTick();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-link {
  text-decoration: underline;
}
.border-preview {
  border: 2px dashed #979797;
  padding: 2rem;
}
.wrap-panel {
  position: relative;
  width: 70%;
  max-width: 370px;
}
.layout {
  width: 100%;
  /* border-radius: 0.75rem; */
  position: relative;
  overflow: hidden;
  height: 100%;
  aspect-ratio: 9 / 16;
  /*
  background: gray; */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: whitesmoke;
  /* justify-content: space-between; */
}
.preview-container {
  display: flex;
  justify-content: center;
  /* border: 1px dashed whitesmoke; */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='5' stroke-dasharray='4%2c15' stroke-dashoffset='27' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 0.75rem;
}
.navbar {
  height: 80px;
  width: 100%;
  justify-content: center;
  background-color: var(--theme-navigation-bar-color);
  transition: background-color 0.1s linear;
  box-shadow: 1px 1px 5px -3px black;
}

.previewBox {
  width: 40%;
}
.rich-menu {
  overflow: hidden;
  position: relative;
}
.menu {
  > div {
    position: absolute;
    top: 0;
    border: 2px solid green;
    height: auto;
    display: flex;

    align-items: center;
    justify-content: center;
    width: 100%;
    &.highlight {
      background: rgba(0, 128, 0, 0.329);
    }
  }
  .sort-menu {
    width: auto;
    color: white;
    font-size: 42px;
    font-weight: bold;
    position: absolute;
    // top: calc(124px + -75%);
    // left: calc(185px - 25%);
    // transform: translate();
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }
}
.rich-menu-panel {
  position: absolute;
  bottom: 0;
  width: 100%;
  img {
    width: 100%;
  }
}
.rich-menu-bottom {
  width: 100%;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 10px;
  background-color: white;
}

.select-custom {
  color: #16274a;
  font-size: 17px;
  margin-bottom: 2px;
  font-weight: bold;
}
</style>
