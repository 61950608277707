import Vue from 'vue'
import App from './App'
import router from './router'

import editor from "@tinymce/tinymce-vue";

import CoreuiVue from '@coreui/vue'

import "tinymce/tinymce";

// Theme
import "tinymce/themes/silver/theme";

// Skins
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/content/default/content.min.css";

// Plugins
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/paste";
import "tinymce/plugins/autoresize";

import "./main.css";
import { iconsSet as icons } from './assets/icons/icons.js'
import { callApi } from "./api/apiUtility";
import { i18n } from "./plugins/i18n";
import VueCookies from "vue-cookies";
import Vuelidate from "vuelidate";
import BootstrapVue from "bootstrap-vue";
import VueMoment from 'vue-moment';
import vueNumeralFilterInstaller from "vue-numeral-filter";
import store from './store';
const moment = require('moment')
    // import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "babel-polyfill";
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

import dotenv from 'dotenv'
dotenv.config()

//Icon
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faTrashAlt, faEye, faSearch, faUser, faChevronRight, faChevronDown, faFilter, faEllipsisV, faFileUpload, faSortDown, faTimesCircle, faDownload, faPencilAlt,faCheck, faBars, faChartBar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faUserSecret, faTrashAlt, faEye, faSearch, faUser, faChevronRight, faChevronDown, faFilter, faEllipsisV, faFileUpload, faSortDown, faTimesCircle, faDownload, faPencilAlt, faCheck, faBars, faChartBar )
Vue.component('font-awesome-icon', FontAwesomeIcon)


Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(vueNumeralFilterInstaller, { locale: "en-gb" });
Vue.use(VueCookies)
Vue.use(BootstrapVue)
Vue.use(Vuelidate)

// moment.locale(VueCookies.isKey("back_office_language") ? VueCookies.get("back_office_language") : 'th');
Vue.use(VueMoment, {
    moment
});
Vue.component('datetime', Datetime);
Vue.component("editor", editor);


let globalData = new Vue({
    data: {
        $isLoading: false
    }
});

Vue.mixin({
    computed: {
        $isLoading: {
            get: function() { return globalData.$data.$isLoading },
            set: function(isLoading) { globalData.$data.$isLoading = isLoading; }
        }
    }
})

Vue.prototype.$language = VueCookies.isKey("back_office_language") ? VueCookies.get("back_office_language") : 'th';
Vue.prototype.$displayName = VueCookies.isKey("back_office_displayName") ? VueCookies.get("back_office_displayName") : '';
Vue.prototype.$callApi = callApi;

// Choose MODE (INHOME, DEV , PROD )
const MODE = `${process.env.VUE_APP_MODE}` || "DEV-DOSETECH";
///// PROD
if (MODE == "DEV-DOSETECH") {
    // Vue.prototype.$baseUrl = "http://devapi-skl-admin.dosetech.co";
    Vue.prototype.$baseUrl = "https://dev-skl-line-admin-api.dosetech.co";
    Vue.prototype.$settingAzure = {
        client_id: "9109bc8d-11ea-4339-8d81-81a840d64be0",
        endpoint: "https://login.microsoftonline.com/dosetech.co",
    };
}
if (MODE == "DEV-SKL") {
    Vue.prototype.$baseUrl = "https://dev-skl-line-admin-api.dosetech.co";
    Vue.prototype.$settingAzure = {
        client_id: "e8c44972-5e93-4f72-9453-f25b6a230183",
        endpoint: "https://login.microsoftonline.com/d9b01593-563c-47d5-92ab-1369de981adc",
    };
}
if (MODE == "PROD") {
    Vue.prototype.$baseUrl = "https://line-admin-line-api.skl.co.th";
    Vue.prototype.$settingAzure = {
        client_id: "ddc91c65-488a-414a-a62a-cf6ef686c1e2",
        endpoint: "https://login.microsoftonline.com/e16fcb08-abd8-4aeb-b0f3-20ab354f7a8a",
    };
}

Vue.prototype.$formatDate = "DD MMM YYYY";
Vue.prototype.$formatDateTime = "DD MMM YYYY HH:mm";
Vue.prototype.$menuList = [];
Vue.prototype.$formatTime = "HH:mm";
Vue.prototype.$headers = {
    "API-KEY": "SK1ADM1FND",
    "Content-Type": "application/json",
    culture: Vue.prototype.$language,
    Authorization: VueCookies.isKey("token") ? `Bearer ${VueCookies.get('token')}` : "null"
}
Vue.prototype.$headersZip = {
    "API-KEY": "SK1ADM1FND",
    "Content-Type": "application/zip",
    culture: Vue.prototype.$language,
    Authorization: VueCookies.isKey("token") ? `Bearer ${VueCookies.get('token')}` : "null"
}

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!VueCookies.get("token")) {
            next({
                path: "/login",
            })
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.requiresIsLogin)) {
        if (VueCookies.get("token")) {
            next({
                path: "/",
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

new Vue({
    el: '#app',
    library,
    i18n,
    router,
    icons,
    store,
    template: '<App/>',
    components: {
        App
    },
})
