<template>
  <div>
    <b-container class="container-box">
      <b-row class="no-gutters">
        <b-col>
          <h1 class="mr-sm-4 header-tablepage">RICH MENU DETAIL</h1>
        </b-col>
      </b-row>

      <div
        class="no-gutters bg-white-border mt-4"
        v-bind:class="$isLoading ? 'd-none' : 'd-block'"
      >
        <div class="px-4 px-sm-5 py-4">
          <div>
            <HeaderLine
              :text="`General Information ${isDupe ? '[ Duplicate ]' : ''}`"
              class="mb-3"
              :isLine="false"
            />
          </div>
          <div class="">
            <b-row>
              <b-col md="6">
                <InputText
                  v-model="form.name"
                  isRequired
                  textFloat="Name"
                  placeholder="Name"
                  type="text"
                  :isValidate="$v.form.name.$error"
                  :v="$v.form.name"
                  :disabled="id != 0"
                />
              </b-col>
              <!-- <b-col md="6">
                <InputText
                  v-model="form.richMenuAlias"
                  textFloat="Alias"
                  placeholder="Alias"
                  type="text"
                  :isValidate="$v.form.richMenuAlias.$error"
                  :v="$v.form.richMenuAlias"
                  :disabled="id != 0"
                />
              </b-col> -->

              <b-col cols="6">
                <InputSelect
                  title="Member Type"
                  valueField="value"
                  textField="text"
                  v-bind:options="memberOptions"
                  v-model="form.isMember"
                  @onDataChange="(val) => (form.isMember = val)"
                  :disabled="id != 0"
                >
                  <template v-slot:option-first>
                    <b-form-select-option :value="null" disabled
                      >-- Select Member Type --</b-form-select-option
                    >
                  </template>
                </InputSelect>
              </b-col>
            </b-row>
          </div>
          <hr />
          <HeaderLine text="Template" class="my-3" :isLine="false" />

          <Template ref="template" class="" :data="form" :id="id"></Template>

          <b-row class="mt-5">
            <b-col md="6">
              <b-button to="/rich-menu" class="btn-details-set">{{
                this.id == 0 ? "CANCEL" : "BACK"
              }}</b-button>
            </b-col>
            <b-col md="6" class="text-sm-right" v-if="this.id == 0">
              <button
                type="button"
                class="btn btn-success btn-details-set ml-md-2 text-uppercase"
                @click="saveForm()"
              >
                Save
              </button>
            </b-col>
            <b-col md="6" class="text-sm-right" v-else>
              <button
                type="button"
                class="btn btn-success btn-details-set ml-md-2 text-uppercase"
                @click="duplicate()"
              >
                Duplicate
              </button>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>

    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText.vue";
import Template from "./components/Template.vue";
import { required } from "vuelidate/lib/validators";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import HeaderLine from "@/components/HeaderLine";
import InputSelect from "../../../components/inputs/InputSelect.vue";

const eng_number = (val) => {
  if (!val) return true;
  if (val.match("[a-z\s0-9-]+$")) {
    return true;
  }
  return false;
};
export default {
  components: {
    ModalAlertError,
    InputText,
    Template,
    ModalAlert,
    HeaderLine,
    InputSelect,
  },

  data() {
    return {
      memberOptions: [
        { text: "Non-member", value: false },
        { text: "Member", value: true },
      ],
      isLoading: true,
      form: {
        name: "",
        richMenuAlias: "",
        isMember: false,
        imageUri: "",
      },
      id: this.$route.params.id,
      templateOptions: [],
      modalMessage: "",
      isDupe: false
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        // richMenuAlias: {
        //   eng_number,
        // },
      },
    };
  },
  async created() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      this.$isLoading = true;

      if (this.id > 0) {
        let resData = await this.$callApi(
          "get",
          `${this.$baseUrl}/api/lineRich/${this.id}`,
          null,
          this.$headers,
          null
        );
        if (resData.result == 1) {
          this.form = resData.detail;
        }
      }

      this.$isLoading = false;
    },
    async saveForm() {
      this.$refs.template.$v.$touch();
      this.$v.form.$touch();

      if (this.$refs.template.$v.$error || this.$v.form.$error) return;
      this.$isLoading = true;
      let payload = {
        ...this.$refs.template.form,
        ...this.form,
        chatBarText: this.$refs.template.form.chatBarText,
        imageUri: this.$refs.template.form.imageUri,
      };
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/lineRich/CreateRichMenu`,
        null,
        this.$headers,
        payload
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        console.log(this.$refs.modalAlert);
        if (!this.form.isMember) {
          await this.syncRichMenuToUser(resData.detail.id);
        }

        setTimeout(this.$router.push("/rich-menu"), 4000);
      } else {
        this.$refs.modalAlertError.show();
      }

      this.$isLoading = false;
    },
    async syncRichMenuToUser(id) {
      let payload = {
        LineRichId: id,
        IsMember: false,
      };

      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/lineRich/SyncRichMenuToUser`,
        null,
        this.$headers,
        payload
      );
    },
    duplicate() {
      this.id = 0;
      this.isDupe = true
    },
  },
};
</script>

<style lang="scss" scoped>
.text-link {
  text-decoration: underline;
}
</style>
